<template>
  <div :class="['external-delivery-targets', isLive && 'live-external-delivery-targets']">
    <Loader :isVisible="isImmediateLoading" />
    <div :class="['header', isLive && 'live-header']">
      <div class="title">
        External Delivery Targets
      </div>
      <div
        v-if="!isLive"
        class="header-btn"
      >
        <a
          :class="['add-button', (deliveryTargets && deliveryTargets.length >= 6)
            ? 'disabled-add-button'
            : ''
          ]"
          @click="addTargets"
        >
          <inline-svg
            :src="iconAdd"
            class="svg-icon add-icon"
          >
          </inline-svg>
          <span class="btn-name">
            Add
          </span>
        </a>
      </div>
      <div v-else>
        <Button
          :title="'Add Target'"
          :classType="'primary'"
          :eventFunction="addTargets"
          :disabled="deliveryTargets && deliveryTargets.length >= 6"
        />
      </div>
    </div>
    <ListDeliveryTargets
      :deliveryTargets="deliveryTargets"
      :handleEdit="handleEdit"
      :handleRemove="handleRemove"
      :handleStateChange="handleStateChange"
      :handleSyncChange="handleSyncChange"
      :showRecorderSync="showRecorderSync"
      :errors="errors"
    />
    <ExternalDeliveryTargetsModal
      v-if="isModal"
      :key="fbEditData"
      ref="deliveryTargetModal"
      :editDeliveryTarget="editDeliveryTarget"
      :onEditDeliveryTargetChange="onEditDeliveryTargetChange"
      :onCancel="handleModalClose"
      :handleModalSubmit="handleModalSubmit"
      :itemErrors="editDeliveryTargetErrors"
      :fbEditData="hasResetFb ? null : fbEditData"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import {
  ACTIVE_FB_DATA_EDIT,
  ADD_DELIVERY_TARGET_REQUEST,
  CHANGE_DELIVERY_TARGET_REQUEST,
  DELETE_DELIVERY_TARGET_REQUEST,
  EDIT_DELIVERY_TARGET_REQUEST,
  EDIT_DELIVERY_TARGET_STATE_REQUEST,
  GET_FB_TARGET_REQUEST,
  RESET_FB_DATA_EDIT,
} from '../../../store/actions/liveEvent/liveEvent';
import { SET_EDIT_FORM } from '../../../store/actions/common';
import CONFIG from '../../../constants/config';

import iconAdd from '../../../assets/icons/media-add.svg';
import ExternalDeliveryTargetsModal from './ExternalDeliveryTargetsModal.vue';
import ListDeliveryTargets from './ListDeliveryTargets.vue';
import Loader from '../../common/Loader.vue';
import Button from '../../common/Button.vue';

export default {
  name: 'ExternalDeliveryTargets',
  components: {
    Button,
    Loader,
    ExternalDeliveryTargetsModal,
    ListDeliveryTargets,
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    deliveryTargets: {
      type: Array,
      default: null,
    },
    isImmediateSave: {
      type: Boolean,
      default: false,
    },
    showSync: {
      type: Boolean,
      default: true,
    },
    isLive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      iconAdd,
      isModal: false,
      isEdit: false,
      editDeliveryTarget: null,
      editDeliveryTargetIndex: null,
      editDeliveryTargetErrors: null,
      fbEditData: null,
    };
  },
  computed: {
    ...mapState({
      isImmediateLoading: (state) => state.liveStream.isImmediateLoading,
      hasResetFb: (state) => state.liveStream.hasResetFb,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
      'fbDataComputed',
    ]),
    scheduleId() {
      return this.getCurrentAccount.schedule.id;
    },
    showRecorderSync() {
      return !this.isImmediateSave && this.showSync;
    },
  },
  methods: {
    handleRemove(index) {
      this.$store.dispatch(CHANGE_DELIVERY_TARGET_REQUEST, true);
      if (this.isImmediateSave) {
        const params = {
          deliveryTargetId: this.deliveryTargets[index].id ?? null,
          accountId: this.getCurrentAccountId,
        };

        this.$store.dispatch(DELETE_DELIVERY_TARGET_REQUEST, params)
          .then(() => {
            this.deliveryTargets.splice(index, 1);
            this.$toasted.global.success({
              message: CONFIG.successMessages.deleteScheduleTargetMessage,
            });
          })
          .catch((errors) => {
            if (errors.status === CONFIG.statuses.notFound) {
              this.deliveryTargets.splice(index, 1);
              this.$toasted.global.success({
                message: CONFIG.successMessages.deleteScheduleTargetMessage,
              });
            } else {
              this.$toasted.global.error({
                message: CONFIG.errorMessages.commonServerError,
              });
            }
          });
      } else {
        this.deliveryTargets.splice(index, 1);
      }
    },
    handleModalSubmit(item) {
      return new Promise((resolve, reject) => {
        if (this.isImmediateSave) {
          delete item.status;
          delete item.id;

          let result = {};

          if (item.type === 'facebook') {
            result = {};
            Object.entries(item.params).forEach(([key, value]) => {
              result[key] = value;
            });
            result.recorderSync = item.recorderSync;
            result.type = item.type;
            result.enabled = item.enabled;

            if (result.facebookDestType === 'account' || result.facebookDestType === 'group') {
              delete result.facebookDestName;
            }
          } else {
            result = item;
          }

          const params = {
            data: result,
            accountId: this.getCurrentAccountId,
          };

          if (!this.isEdit) {
            params.data.accountSchedule = this.scheduleId;
            this.$store.dispatch(ADD_DELIVERY_TARGET_REQUEST, params)
              .then((data) => {
                this.deliveryTargets.push(this.transformBEDeliveryTarget(data));
                this.handleModalClose();
                this.$toasted.global.success({
                  message: CONFIG.successMessages.addScheduleTargetMessage,
                });
                this.$store.dispatch(SET_EDIT_FORM, false);
                resolve();
              })
              .catch((errors) => {
                this.$refs.deliveryTargetModal.handleErrorResponse(errors);
                reject();
              });
          } else {
            params.data.enabled = this.deliveryTargets[this.editDeliveryTargetIndex]
              && this.deliveryTargets[this.editDeliveryTargetIndex].enabled;
            params.data.recorderSync = this.deliveryTargets[this.editDeliveryTargetIndex]
              && this.deliveryTargets[this.editDeliveryTargetIndex].recorderSync;
            params.deliveryTargetId = this.editDeliveryTarget.id;

            this.$store.dispatch(EDIT_DELIVERY_TARGET_REQUEST, params)
              .then((data) => {
                this.deliveryTargets.splice(
                  this.editDeliveryTargetIndex,
                  1,
                  this.transformBEDeliveryTarget(data),
                );
                this.editDeliveryTargetIndex = null;
                this.editDeliveryTargetErrors = null;
                this.handleModalClose();
                this.$toasted.global.success({
                  message: CONFIG.successMessages.editScheduleTargetMessage,
                });
                this.$store.dispatch(SET_EDIT_FORM, false);
                resolve();
              })
              .catch((errors) => {
                if (errors.status === CONFIG.statuses.notFound) {
                  this.$toasted.global.error({
                    message: errors.status === CONFIG.statuses.notFound
                      ? CONFIG.errorMessages.deliveryTargetNotFoundError
                      : CONFIG.errorMessages.commonServerError,
                  });
                } else {
                  this.$refs.deliveryTargetModal.handleErrorResponse(errors);
                }
                reject();
              });
          }
        } else {
          if (!this.isEdit) {
            this.deliveryTargets.push(item);
          } else {
            item.enabled = this.deliveryTargets[this.editDeliveryTargetIndex]
              && this.deliveryTargets[this.editDeliveryTargetIndex].enabled;
            item.recorderSync = this.deliveryTargets[this.editDeliveryTargetIndex]
              && this.deliveryTargets[this.editDeliveryTargetIndex].recorderSync;
            this.deliveryTargets.splice(this.editDeliveryTargetIndex, 1, item);
            this.editDeliveryTargetIndex = null;
            this.editDeliveryTargetErrors = null;
          }
          this.handleModalClose();
          resolve();
        }
      });
    },
    addTargets() {
      if (this.deliveryTargets && this.deliveryTargets.length < 6) {
        this.isModal = true;
        this.$store.dispatch(CHANGE_DELIVERY_TARGET_REQUEST, true);
      }
    },
    handleModalClose() {
      this.editDeliveryTarget = null;
      this.isModal = false;
      this.isEdit = false;
    },
    handleEdit(item, index, errors) {
      this.$store.dispatch(CHANGE_DELIVERY_TARGET_REQUEST, true);
      if (item.id && item.params && item.params.facebookEncryptedAccessToken) {
        this.$store.dispatch(ACTIVE_FB_DATA_EDIT);
      }

      if (item.id && item.params && !item.params.facebookEncryptedAccessToken) {
        this.$store.dispatch(RESET_FB_DATA_EDIT);
      }

      if (item.id && item.type === 'facebook') {
        const params = {
          accountId: this.getCurrentAccountId,
          deliveryTargetId: item.id,
        };
        this.$store.dispatch(GET_FB_TARGET_REQUEST, params)
          .then((res) => {
            this.fbEditData = res.data;
          })
          .catch(() => {
            this.fbEditData = null;
          });
      }

      this.editDeliveryTarget = item;
      this.editDeliveryTargetIndex = index;
      this.editDeliveryTargetErrors = errors;
      this.isModal = true;
      this.isEdit = true;
    },
    handleStateChange(item) {
      this.$store.dispatch(CHANGE_DELIVERY_TARGET_REQUEST, true);
      if (this.isImmediateSave) {
        const params = {
          accountId: this.getCurrentAccountId,
          deliveryTargetId: item.id,
          data: {
            enabled: !!item.enabled,
          },
        };

        this.$store.dispatch(EDIT_DELIVERY_TARGET_STATE_REQUEST, params)
          .then(() => {
            this.$toasted.global.success({
              message: item.enabled
                ? CONFIG.successMessages.enableScheduleTargetStateMessage
                : CONFIG.successMessages.disableScheduleTargetStateMessage,
            });
          })
          .catch((errors) => {
            item.enabled = !item.enabled;

            this.$toasted.global.error({
              message: errors.status === CONFIG.statuses.notFound
                ? CONFIG.errorMessages.deliveryTargetNotFoundError
                : CONFIG.errorMessages.commonServerError,
            });
          });
      }
    },
    handleSyncChange() {
      this.$store.dispatch(CHANGE_DELIVERY_TARGET_REQUEST, true);
    },
    onEditDeliveryTargetChange(value) {
      this.editDeliveryTarget = value;
    },
    transformBEDeliveryTarget(item) {
      if (item.type === 'facebook') {
        const result = {
          params: {},
          fbData: {},
        };

        Object.entries(item).forEach(([key, value]) => {
          result[key] = value;
        });

        Object.entries(item).forEach(([key, value]) => {
          result.params[key] = value;
        });

        Object.entries(item).forEach(([key, value]) => {
          result.fbData[key] = value;
        });

        return result;
      }

      return item;
    },
  },
};
</script>
<style scoped>
  .title {
    color: #4c5057;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 18px;
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: 300px;
  }

  .live-header {
    margin-bottom: 50px;
  }

  .external-delivery-targets {
    max-width: 500px;
  }

  .add-button {
    display: flex;
  }

  .btn-name {
    margin-left: 5px;
  }

  .add-button:hover {
    cursor: pointer;
  }

  .disabled-add-button:hover {
    cursor: not-allowed;
  }

  .live-external-delivery-targets {
    max-width: 100%;
  }
</style>
